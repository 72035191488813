import React, { Suspense, useRef, useState, useEffect } from "react"
import { useFrame, useLoader } from "@react-three/fiber"

import { TextureLoader } from "three"
import { useAppContext } from "../../stores/appStore"

function Moon() {
    const moonTexture = useLoader(TextureLoader, "/textures/2k_moon.jpg")
    const moonRef = useRef()
    const [move, setMove] = useState(false)
    const { altitudeAnimationInProgress } = useAppContext()

    useEffect(() => {
        if(altitudeAnimationInProgress){
            setTimeout(() => {
                setMove(true)
            },250);
        }
        else{
            setMove(false)
        }
    }, [altitudeAnimationInProgress])
    

    // Rotate the stars slowly
    useFrame(() => {
        if (moonRef.current) {
            if (move) {
                moonRef.current.position.x += -0.5
                moonRef.current.position.y += -0.3
            } else {
                moonRef.current.position.x = 50
                moonRef.current.position.y = 70
                moonRef.current.position.z = -200
            }
        }
    })

    return (
        <Suspense fallback={null}>
            <mesh
                position={[50, 70, -200]}
                rotation={[50, 55, 50]}
                receiveShadow
                ref={moonRef}
            >
                <sphereGeometry args={[2, 32, 32]} />
                <meshStandardMaterial map={moonTexture} />
            </mesh>
        </Suspense>
    )
}

export default Moon
