import React from "react"
import styles from './ActualRoundNo.module.css'
import { useAppContext } from "../../stores/appStore"

export default function ActualRoundNo() {
    const {prepareDraw, altitude, actualRoundNo, animationFinished} = useAppContext()
    return (
        <div className={`${styles.round} ${(altitude > 0.9 || prepareDraw) && styles.show} ${animationFinished &&styles.center}`}>
            RUNDA {actualRoundNo}
        </div>
    )
}
