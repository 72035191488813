import React, { useRef, useMemo, useEffect, useState } from "react"
import { useFrame } from "@react-three/fiber"
import * as THREE from "three"
import { useAppContext } from "../../../stores/appStore"

const RocketFlame = () => {
    const { altitude, prepareDraw } = useAppContext()
    const [speedMultiplier, setSpeedMultiplier] = useState(0.0025) // to 0.015
    const particleCount = 800
    const initialRadius = 0.22
    const maxRings = 8 // Number of rings
    const particleSystemRef = useRef()

    useEffect(() => {
        if (!prepareDraw && altitude) {
            setTimeout(() => {
                setSpeedMultiplier(0.007)
            }, 300);
            setTimeout(() => {
                setSpeedMultiplier(0.01)
            }, 600);
            setTimeout(() => {
                setSpeedMultiplier(0.015)
            }, 1000);
            
        }
        else{
            setSpeedMultiplier(0.0025)
        }
    }, [altitude, prepareDraw])

    const ageRefs = useMemo(
        () =>
            Array.from({ length: maxRings }, () =>
                new Float32Array(particleCount / maxRings).fill(0)
            ),
        []
    )

    // Define ring parameters: speed, color, and lifetime
    const ringParams = useMemo(() => {
        return Array.from({ length: maxRings }, (_, i) => ({
            speed: 0.0005 + i * speedMultiplier,
            color: new THREE.Color(
                `hsl(${Math.floor((i / maxRings + 2) * 100)}, 80%, 50%)`
            ),
            lifetime: 11 + i * 0.15
        }))
    }, [speedMultiplier])

    // Create particle positions in concentric circles with shrinking radius
    const particles = useMemo(() => {
        const positions = new Float32Array(particleCount * 3)
        for (let i = 0; i < particleCount; i++) {
            const ringIndex = Math.floor(i / (particleCount / maxRings))
            const angle = (i % 20) * (Math.PI / 10)
            const radius = initialRadius * (1 - ringIndex / maxRings)
            positions[i * 3] = Math.cos(angle) * radius
            positions[i * 3 + 1] = 0 // y will be updated in animation
            positions[i * 3 + 2] = Math.sin(angle) * radius
        }
        return positions
    }, [])

    useFrame(() => {
        const positions =
            particleSystemRef.current.geometry.attributes.position.array

        for (let ringIndex = 0; ringIndex < maxRings; ringIndex++) {
            const { speed, lifetime } = ringParams[ringIndex]
            const ringParticleCount = particleCount / maxRings

            for (let i = 0; i < ringParticleCount; i++) {
                const index = ringIndex * ringParticleCount + i
                const age = ageRefs[ringIndex][i]
                const shrinkFactor = 1 - Math.pow(age / lifetime, 2)

                // Move particles downward (backward) along the y-axis
                positions[index * 3 + 1] -= speed

                positions[index * 3] =
                    positions[index * 3] * 0.98 +
                    Math.cos((i % 20) * (Math.PI / 10)) *
                        initialRadius *
                        (1 - ringIndex / maxRings) *
                        shrinkFactor *
                        -0.02
                positions[index * 3 + 2] =
                    positions[index * 3 + 2] * 0.98 +
                    Math.sin((i % 20) * (Math.PI / 10)) *
                        initialRadius *
                        (1 - ringIndex / maxRings) *
                        shrinkFactor *
                        -0.02

                // Age particles and reset if lifetime exceeded
                ageRefs[ringIndex][i]++
                if (ageRefs[ringIndex][i] > lifetime) {
                    positions[index * 3 + 1] = 0 // Reset y position for backward effect
                    ageRefs[ringIndex][i] = 0
                    positions[index * 3] =
                        Math.cos((i % 20) * (Math.PI / 10)) *
                        initialRadius *
                        (1 - ringIndex / maxRings)
                    positions[index * 3 + 2] =
                        Math.sin((i % 20) * (Math.PI / 10)) *
                        initialRadius *
                        (1 - ringIndex / maxRings)
                }
            }
        }

        particleSystemRef.current.geometry.attributes.position.needsUpdate = true
    })

    return (
        <group position={[0, 0.19, 0]}>
            {ringParams.map((ring, i) => (
                <points key={i} ref={particleSystemRef}>
                    <bufferGeometry attach="geometry">
                        <bufferAttribute
                            attach="attributes-position"
                            array={particles}
                            count={particleCount}
                            itemSize={3}
                        />
                    </bufferGeometry>
                    <pointsMaterial
                        attach="material"
                        color={ring.color}
                        size={0.12}
                        transparent
                        opacity={0.5} // Higher opacity for glow
                        blending={THREE.AdditiveBlending} // Additive blending for brightness
                        sizeAttenuation={true}
                        depthWrite={false}
                    />
                </points>
            ))}
        </group>
    )
}

export default React.memo(RocketFlame)