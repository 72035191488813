import React, { useState, useEffect } from "react"
import styles from "./Time.module.css"
import { useAppContext } from "../../stores/appStore"

export default function Time() {
    const { remainingSeconds, history } = useAppContext()
    const [seconds, setSeconds] = useState(remainingSeconds)

    useEffect(() => {
        let interval

        // Start countdown when `prepareDraw` is true
        if (remainingSeconds) {
            setSeconds(remainingSeconds) // Reset countdown to 5 seconds when `prepareDraw` is true

            interval = setInterval(() => {
                setSeconds((prev) => {
                    if (prev > 0) {
                        return prev - 1 // Decrease the seconds
                    } else {
                        clearInterval(interval) // Stop the interval when it reaches 0
                        return 0
                    }
                })
            }, 1000) // Run every second
        }

        // Cleanup interval on component unmount or when `prepareDraw` changes
        return () => clearInterval(interval)
    }, [remainingSeconds])

    return (
        <div className={styles.wrapper}>
            {/* {icon} */}
            <span>
                {remainingSeconds && history.length
                    ? `0:${seconds < 10 ? 0 : ""}${seconds}`
                    : " "}
            </span>
        </div>
    )
}