import React, { useState, useEffect } from "react"
import * as THREE from "three"
import { useAppContext } from "../../../stores/appStore"

const JetExhaustFlame = ({
    position = [0, -6.4, 0],
    color = 0xffff00,
    intensity = 0.5
}) => {
    const { altitudeAnimationInProgress } = useAppContext()
    const [opacity, setOpacity] = useState(0) // Initial opacity is 0 (hidden)
    const [diametre, setDiametre] = useState(5)
    const targetOpacity = altitudeAnimationInProgress ? 0.025 : 0 // Full opacity or fade out

    useEffect(() => {
        // Simple fade-in or fade-out effect by updating opacity
        let fadeEffect
        if (altitudeAnimationInProgress) {
            fadeEffect = setInterval(() => {
                setOpacity((prevOpacity) =>
                    Math.min(prevOpacity + 0.0013, 0.025)
                )
                setDiametre((prev) => Math.min(prev + 1, 6))
            }, 50)
        } else {
            fadeEffect = setInterval(() => {
                setOpacity((prevOpacity) => Math.max(prevOpacity - 0.005, 0))
                setDiametre((prev) => Math.max(prev - 1, 0))
            }, 50)
        }
        return () => clearInterval(fadeEffect)
    }, [altitudeAnimationInProgress])

    return (
        <>
            {/* PointLight to simulate glow */}
            <pointLight
                position={[0, -1, 0]}
                intensity={2} // Increase intensity for a glowing effect
                distance={5} // Control how far the glow reaches
                decay={2} // How quickly the light fades out
                color={new THREE.Color(color)}
            />
            <pointLight
                position={[0, -3, 0]}
                intensity={3} // Increase intensity for a glowing effect
                distance={5} // Control how far the glow reaches
                decay={2} // How quickly the light fades out
                color={new THREE.Color(color)}
            />
            {/* Cone geometry to simulate light rays */}
            <mesh position={position} rotation={[0, 0, 0]}>
                <coneGeometry args={[diametre, 14, 128]} />
                {/* (radius, height, radial segments) */}
                <meshStandardMaterial
                    color={new THREE.Color(color)} // Ensure color is valid
                    transparent={true}
                    opacity={opacity} // Opacity controlled by animation
                    emissive={new THREE.Color(color)} // Ensure emissive color is valid
                    emissiveIntensity={0.1} // Glow strength
                    side={THREE.DoubleSide} // Make the cone visible from both sides
                    blending={THREE.AdditiveBlending} // Additive blending for light rays
                />
            </mesh>
        </>
    )
}

export default JetExhaustFlame
