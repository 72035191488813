import React, { useMemo, useRef, useState, useEffect } from "react";
import { useFrame } from "@react-three/fiber";
import { useAppContext } from "../../stores/appStore";

const StarsAnimation = () => {
  const starsRef = useRef();
  const { altitudeAnimationInProgress } = useAppContext();

  const [mx, setMx] = useState(0.3);
  const [my, setMy] = useState(0.15);

  const [targetMx, setTargetMx] = useState(3);
  const [targetMy, setTargetMy] = useState(1.5);

  // Smooth transition effect
  useEffect(() => {
    let animationFrameId;

    const smoothTransition = () => {
      setMx((prevMx) => prevMx + (targetMx - prevMx) * 0.005);
      setMy((prevMy) => prevMy + (targetMy - prevMy) * 0.005);

      animationFrameId = requestAnimationFrame(smoothTransition);
    };

    animationFrameId = requestAnimationFrame(smoothTransition);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [targetMx, targetMy]);

  useEffect(() => {
    if (altitudeAnimationInProgress) {
      setTargetMx(120);
      setTargetMy(60);
    } else {
      setTargetMx(3);
      setTargetMy(1.5);
    }
  }, [altitudeAnimationInProgress]);

  // Generate star positions within the visible bounds for initial render
  const generateInitialStarPosition = () => {
    const x = (Math.random() - 0.5) * 1000; // Generate x position within visible bounds
    const y = (Math.random() - 0.5) * 1000; // Generate y position within visible bounds
    const z = 0;
    return { x, y, z };
  };

  // Generate star positions outside the bounds for reset
  const generateOutsideStarPosition = () => {
    const x = (Math.random() - 0.5) * 1200 + 700; // Generate x position just offscreen
    const y = (Math.random() - 0.5) * 1200 + 700; // Generate y position just offscreen
    const z = 0;
    return { x, y, z };
  };

  // Initialize star positions within visible bounds for first render
  const starPositions = useMemo(() => {
    const vertices = [];
    for (let i = 0; i < 600; i++) {
      const { x, y, z } = generateInitialStarPosition();
      vertices.push(x, y, z);
    }
    return new Float32Array(vertices);
  }, []);

  useFrame((state, delta) => {
    if (starsRef.current) {
      const positions = starsRef.current.geometry.attributes.position.array;

      const adjustedMx = mx * delta;
      const adjustedMy = my * delta;

      for (let i = 0; i < positions.length; i += 3) {
        positions[i] -= adjustedMx;
        positions[i + 1] -= adjustedMy;

        // If a star moves completely out of bounds, reset it to just outside the visible area
        if (positions[i] < -300 || positions[i + 1] < -300) {
          const { x, y, z } = generateOutsideStarPosition();
          positions[i] = x;
          positions[i + 1] = y;
          positions[i + 2] = z;
        }
      }

      starsRef.current.geometry.attributes.position.needsUpdate = true;
    }
  });

  return (
    <points ref={starsRef} position={[0, 0, -300]}>
      <bufferGeometry>
        <bufferAttribute
          attach="attributes-position"
          array={starPositions}
          count={starPositions.length / 3}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial color={0xffffff} size={1} />
    </points>
  );
};

export default StarsAnimation;
