import React from "react"
import { Canvas } from "@react-three/fiber"
import Moon from "./models/Moon"
import Rocket from "./models/Rocket"
// import { OrbitControls } from "@react-three/drei"
import {
    EffectComposer,
    Bloom,
} from "@react-three/postprocessing"
import StarsAnimation from "./models/StarsAnimation"
import GradientBackground from "./models/GradientBackground"
import Asteroid from "./models/Asteroid"
import Planet from "./models/Planet"

export default function Scene() {
    return (
        <Canvas
            style={{ height: "100vh" }}
            camera={{ fov: 30, rotation: false }}
        >
            {/* <color attach="background" args={["#100e17"]} /> */}
            {/* <color attach="background" args={["#1f1936"]} /> */}
            <GradientBackground />
            <ambientLight intensity={0.5} />
            <directionalLight intensity={1.5} position={[5, 10, 2]} />
            <Moon />
            <Rocket />
            {/* <OrbitControls /> */}
            <StarsAnimation />
            <Asteroid />
            <Planet />
            <EffectComposer>
                <Bloom
                    luminanceThreshold={0.3}
                    luminanceSmoothing={0.9}
                    intensity={0.5}
                />
            </EffectComposer>
        </Canvas>
    )
}
