import React from "react"
import styles from "./Header.module.css"
import { useAppContext } from "../../stores/appStore"
import Time from "./Time"

export default function Header() {
    const {
        localJackpot = null ,
        globalJackpot = null,
        prepareDraw,
        altitude,
        nextRoundNo,
        remainingSeconds,
        betClosed
    } = useAppContext()

    const animationInProgress = (prepareDraw || altitude)

    return (
        <header
            // className={`${styles.header} ${
            //     animationInProgress && styles.hidden
            // }`}
        >
            <div className={`${styles.left} ${(!animationInProgress && globalJackpot) && styles.show}`}>
                <div className={styles.jackpotWrapper}>
                    <div className={`${styles.jackpotTitle} ${styles.global}`}>
                        JACKPOT GLOBAL
                    </div>
                    <div className={`${styles.jackpot} ${styles.global}`}>
                        {globalJackpot} RON
                    </div>
                </div>
            </div>
            <div className={`${styles.leftSecond} ${(!animationInProgress && nextRoundNo) && styles.show}`}>
                <div className={styles.nextRoundTitle}>URMEAZĂ RUNDA</div>
                <div className={styles.nextRoundNo}>{nextRoundNo}</div>
            </div>

            <div className={`${styles.center} ${!animationInProgress && styles.show}`}>
                <img src="/images/logo-light.svg" alt="" />
            </div>
            <div className={`${styles.rightSecond} ${(!animationInProgress && remainingSeconds) && styles.show}`}>
                <div className={styles.nextRoundTitle}>TIMP RĂMAS</div>
                <Time />
            </div>
            <div className={`${styles.right} ${(!animationInProgress && localJackpot) && styles.show}`}>
                <div className={styles.jackpotWrapper}>
                    <div className={`${styles.jackpotTitle} ${styles.local}`}>
                        JACKPOT LOCAL
                    </div>
                    <div className={`${styles.jackpot} ${styles.local}`}>
                        {localJackpot} RON
                    </div>
                </div>
            </div>
        </header>
    )
}
