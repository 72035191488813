import React, { Suspense, useRef, useEffect } from "react"
import { useFrame, useLoader } from "@react-three/fiber"
import { TextureLoader } from "three"
import { useAppContext } from "../../stores/appStore"

import gsap from "gsap"

function Planet({ position = [60, 5, -100] }) {
    const earthTexture = useLoader(TextureLoader, "/textures/earthmap1k.jpg")
    const planetRef = useRef()
    const { altitudeAnimationInProgress } = useAppContext()

    const defaultPosition = () => {
        gsap.set(planetRef.current.position, {
            x: 65,
            y: 7,
            z: -100
        })
        gsap.set(planetRef.current.rotation, {
            x: 0,
            y: 0,
            z: 0
        })
    }

    const movingPosition = () => {
        if (altitudeAnimationInProgress)
            gsap.to(planetRef.current.position, {
                x: -50,
                y: -40,
                z: -50,
                duration: 4
                // ease: "power4.inOut"
            })
    }

    useEffect(() => {
        if (altitudeAnimationInProgress) {
            setTimeout(() => {
                movingPosition()
            }, 2000)
        } else {
            defaultPosition()
        }
    }, [altitudeAnimationInProgress])

    // Rotate slowly around the Y axis
    useFrame(() => {
        if (planetRef.current) {
            planetRef.current.rotation.y += 0.001
        }
    })

    return (
        <Suspense fallback={null}>
            <mesh ref={planetRef} position={position} castShadow receiveShadow>
                <sphereGeometry args={[5, 32, 32]} />
                <meshStandardMaterial
                    map={earthTexture}
                    color={"#f5f5f5"} // Darker color to simulate distance
                    roughness={1} // Increase roughness to make it less shiny
                    // emissive={"#000000"} // No emissive light
                    // emissiveIntensity={0.1} // Very little emission, to keep it dark
                />
            </mesh>
        </Suspense>
    )
}

export default Planet
