import "./App.css"
import Display from "./components/Display"

import { AppContextProvider } from "./stores/appStore"

function App() {
    return (
        <AppContextProvider>
            <Display />
        </AppContextProvider>
    )
}

export default App
