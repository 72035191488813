export const getAltitudeColor = (altitude)=>{
    const colorsRanges = [
        [1, 1.99, "gray"],
        [2, 5, "yellow"],
        [5.01, 10, "green"],
        [10.01, 100, "blue"], 
        [100.01, 10000, "purple"]
    ]

    const findTableRow = (currentAltitude) => {
        return colorsRanges.find(
            (row) => currentAltitude >= row[0] && currentAltitude <= row[1]
        )
    }

    let currentTableRow = findTableRow(altitude) // Find the corresponding row for the current value
    if (!currentTableRow) return // If no matching row, exit the effect

    // Destructure the values from the found table row
    let [minRange, maxRange, color = 'gray'] = currentTableRow

    return color
}