import React, { Suspense, useRef, useEffect } from "react"
import { useFrame } from "@react-three/fiber"
import { useGLTF } from "@react-three/drei"

import { useAppContext } from "../../stores/appStore"
import gsap from "gsap"
function Asteroid() {
    const { scene: rocketScene } = useGLTF("/models/asteroid.glb")
    const asteroidRef = useRef()
    const pivotRef = useRef()
    const { jackpotHit } = useAppContext()

    const defaultPosition = () => {
        gsap.set(pivotRef.current.position, {
            x: 0,
            y: 50,
            z: -1000
        })
    }

    const comingPosition = () => {
        gsap.to(pivotRef.current.position, {
            x: 0,
            y: 0,
            z: 6,
            duration: 4,
            ease: "power4.inOut"
        })
    }

    useEffect(() => {
        if (jackpotHit) {
            comingPosition()
        } else {
            defaultPosition()
        }
    }, [jackpotHit])

    // ROTATE ASTEROID
    useFrame(() => {
        if (asteroidRef.current && jackpotHit) {
            asteroidRef.current.rotation.x += 0.04
            asteroidRef.current.rotation.y += 0.005
        }
    })

    return (
        <Suspense fallback={null}>
            <group ref={pivotRef} rotation={[0, 0, 0]} position={[0,50,-1000]}>
                <group ref={asteroidRef}>
                    <primitive object={rocketScene} scale={0.5} castShadow />
                </group>
            </group>
        </Suspense>
    )
}

export default Asteroid
