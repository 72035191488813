import React from "react"
import styles from "./TopWins.module.css"
import { convertDateFromApi } from "../../helpers/converters"
import { useAppContext } from "../../stores/appStore"

export default function TopWins() {
    const { topWins, showWins } = useAppContext()

    return (
        <div className={`${styles.topWinsWrapper} ${showWins && styles.show}`}>
            <div className={styles.bar}>
                <div className={styles.title}>TOP 5 CÂȘTIGURI</div>
            </div>

            <div className={styles.topWins}>
                {topWins.map(({ ticketDate, winAmount }, i) => {
                    const lng = topWins.length
                    return (
                        <div
                            key={i}
                            className={styles.win}
                            style={{ animationDelay: `0.${lng - (i + 1)}s` }}
                        >
                            <div className={styles.value}>{winAmount} RON</div>
                            <div className={styles.date}>
                                {convertDateFromApi(ticketDate, "DD.MM.YYYY")}
                            </div>
                        </div>
                    )
                })}
            </div>
            {/* <div className={styles.title}>ULTIMELE 5 ZILE</div> */}
        </div>
    )
}
