import React, { useEffect, useState, useRef } from "react"
import styles from "./Multiplier.module.css"
import { useAppContext } from "../../stores/appStore"
import { getAltitudeColor } from "../../helpers/functions"

export default function Multiplier() {
    const {
        altitude = 0.9,
        rocketTable = [],
        history,
        setAltitudeAnimationInProgress,
        setAnimationFinished: setStoredAnimationFinished
    } = useAppContext()

    const [currentNumber, setCurrentNumber] = useState("1.00")
    const intervalIdRef = useRef(null)
    const hasSetAnimationRef = useRef(false) // New ref to track animation set status
    const [animationInProgress, setAnimationInProgress] = useState(false)
    const [animationFinished, setAnimationFinished] = useState(false)

    useEffect(() => {
        if (altitude && altitude > 1.2 && rocketTable.length > 0) {
            const timeoutId = setTimeout(() => {
                setAnimationInProgress(true)
            }, 1400)

            return () => {
                clearTimeout(timeoutId)
                setAnimationFinished(false)
                hasSetAnimationRef.current = false // Reset when unmounting
            }
        }
    }, [altitude, rocketTable])

    useEffect(() => {
        setAltitudeAnimationInProgress(animationInProgress)
    }, [animationInProgress, setAltitudeAnimationInProgress])

    useEffect(() => {
        setStoredAnimationFinished(animationFinished)
    }, [animationFinished, setStoredAnimationFinished])

    useEffect(() => {
        if (altitude && rocketTable.length > 0) {
            let table = [...rocketTable]
            table.shift()

            const targetNumber = altitude

            const updateNumber = () => {
                setCurrentNumber((prev) => {
                    const findTableRow = (currentAltitude) => {
                        return table.find(
                            (row) =>
                                currentAltitude >= row[0] &&
                                currentAltitude <= row[1]
                        )
                    }

                    let currentTableRow = findTableRow(prev)
                    if (!currentTableRow) return

                    let [minRange, maxRange, minStep, maxStep] = currentTableRow

                    let step = Math.random() * (maxStep - minStep) + minStep
                    step = parseFloat(step.toFixed(2))

                    let nextNumber = parseFloat(prev) + step

                    if (parseFloat(nextNumber) > parseFloat(targetNumber)) {
                        setAnimationInProgress(false)
                        setAnimationFinished(true)
                        clearInterval(intervalIdRef.current)
                        return parseFloat(targetNumber).toFixed(2)
                    } else {
                        return parseFloat(nextNumber).toFixed(2)
                    }
                })
            }

            setTimeout(() => {
                intervalIdRef.current = setInterval(updateNumber, 100)
            }, 1400)

            return () => {
                clearInterval(intervalIdRef.current)
            }
        }
    }, [altitude, rocketTable])

    useEffect(() => {
        setTimeout(() => {
            clearInterval(intervalIdRef.current)
            setCurrentNumber("1.00")
            setAnimationFinished(false)
        }, 1000)
    }, [history])

    // if (altitude < 1) {
    //     return null;
    // }

    return (
        <div
            className={`${styles.wrapper} ${
                styles[getAltitudeColor(currentNumber)]
            } ${altitude > 0.9 && styles.show} ${
                animationFinished && styles.center
            }`}
        >
            x{currentNumber}
        </div>
    )
}
