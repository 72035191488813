import React, { useEffect, useState } from "react"
import styles from "./RoundHistory.module.css"
import { useAppContext } from "../../stores/appStore"
import { getAltitudeColor } from "../../helpers/functions"

export default function RoundHistory() {
    const { history, prepareDraw } = useAppContext()
    const [hidden, setHidden] = useState(true)

    useEffect(() => {
        if (prepareDraw) {
            setHidden(true)
        }
    }, [prepareDraw])

    useEffect(() => {
        setTimeout(() => {
            setHidden(false)
        }, 500)
    }, [history])

  

    return (
        <div className={`${styles.wrapper} ${hidden && styles.hidden}`}>
            {history.map(({ altitude, roundNo }, i) => {
           
                let delay = 0

                // First 10 items (index 0 to 9) with decreasing delay
                if (i < 10) {
                    delay = (10 - i) * 0.1
                }
                // Last 10 items (index 10 to 19) with increasing delay
                else if (i >= 10) {
                    delay = (i - 10) * 0.1
                }

                return (
                    <div
                        className={`${styles.round} ${styles[getAltitudeColor(altitude)]}`}
                        style={{ animationDelay: `${delay}s` }}
                        key={i}
                    >
                        <div className={styles.value}>
                            {parseFloat(altitude).toFixed(2)}x
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
