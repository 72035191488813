import { createContext, useContext, useState, useEffect } from "react"
import { HubConnectionBuilder, LogLevel } from "@aspnet/signalr"
import axios from "axios"

const AppContext = createContext()

export function useAppContext() {
    return useContext(AppContext)
}

export function AppContextProvider({ children }) {
    const [connection, setConnection] = useState(null)
    const [shopId, setShopId] = useState(null)
    const [rocketTable, setRocketTable] = useState([])
    const [remainingSeconds, setRemainingSeconds] = useState(null)
    const [altitude, setAltitude] = useState(null)
    const [altitudeAnimationInProgress, setAltitudeAnimationInProgress] =
        useState(false)
    const [animationFinished, setAnimationFinished] = useState(false)
    const [showWins, setShowWins] = useState(false)
    const [nextRoundNo, setNextRoundNo] = useState(null)
    const [actualRoundNo, setActualRoundNo] = useState(null)
    const [lastRoundNo, setLastRoundNo] = useState(null)
    const [lastRoundAltitude, setLastRoundAltitude] = useState(null)
    const [localJackpot, setLocalJackpot] = useState(null)
    const [globalJackpot, setGlobalJackpot] = useState(null)
    const [history, setHistory] = useState([])
    const [prepareDraw, setPrepareDraw] = useState(false)
    const [topWins, setTopWins] = useState([])
    const [showBoard, setShowBoard] = useState(false)
    const [jackpotHit, setJackpotHit] = useState(null)
    const [showLoading, setShowLoading] = useState(true)
    const [betClosed, setBetClosed] = useState(false)
    const [showCountDown, setShowCountDown] = useState(false)

    // Get ShopID
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const shopIdParam = urlParams.get("shopId")
        setShopId(shopIdParam)
    }, [])

    // Fetch initial data using axios
    useEffect(() => {
        axios
            .get(
                "https://tbet.oddin-platform.com/virtualsapi/api/utils/payTable/Rocket"
            )
            .then((response) => {
                // console.log("Fetched Data:", response.data)
                setRocketTable(response.data) // Example of setting the data
            })
            .catch((error) => {
                console.error("Error fetching initial data:", error)
            })
    }, [])

    // Setup SignalR connection and handlers
    useEffect(() => {
        if (shopId) {
            const newConnection = new HubConnectionBuilder()
                .withUrl(
                    "https://tbet.oddin-platform.com/virtualsweb/RocketHub?shopId=" +
                        shopId
                )
                // .configureLogging(LogLevel.Debug)
                // .withAutomaticReconnect() // Enable automatic reconnect if needed   (NOT AVAILABLE ON)
                .build()

            setConnection(newConnection)

            // Start connection
            if (newConnection) {
                startConnection(newConnection)
            }

            // Cleanup connection on component unmount
            return () => {
                if (newConnection) {
                    newConnection.stop()
                    console.log("SignalR connection stopped.")
                }
            }
        }
    }, [shopId])

    // GET TOP WINS REQUEST
    const getTopWins = () => {
        axios
            .get(
                "https://tbet.oddin-platform.com/virtualsapi/api/rocket/topWins/5/3"
            )
            .then((response) => {
                setTopWins(response.data)
                setShowWins(true)
            })
            .catch((error) => {
                console.error("Error fetching initial data:", error)
            })
    }

    useEffect(() => {
        if (prepareDraw) {
            setActualRoundNo(nextRoundNo)
        }
    }, [prepareDraw, nextRoundNo])

    // Start the SignalR connection and handle events
    const startConnection = async (connection) => {
        try {
            await connection.start()
            console.log("SignalR connection established")

            connection.on("catchUp", (message) => {
                console.log("catchUp:", message)
        
                const {
                    actualRoundNo,
                    lastRoundNo,
                    roundHistory,
                    lastRoundAltitude,
                    remainingSeconds,
                    betsClosed,
                } = message


                if(betsClosed){
                    setPrepareDraw(true)
                    setShowBoard(false)
                    setShowWins(false)
                    setHistory([])
                }
                else{
                    if (roundHistory) {
                        setHistory(roundHistory)
                    }
                    setShowBoard(true)
                    getTopWins()
                }
               
           
                setActualRoundNo(lastRoundNo)
                setNextRoundNo(actualRoundNo)
                setLastRoundAltitude(lastRoundAltitude)

                
                setRemainingSeconds(remainingSeconds)
            })

            connection.on("remainingRoundTime", (message) => {
                // console.log("remainingRoundTime:", message)
                setRemainingSeconds(message.remainingRoundSeconds)
            })

            connection.on("jackpotInfo", (message) => {
                // console.log("jackpotInfo:", message)
                const {
                    localOngoingJackpotAmount,
                    globalOngoingJackpotAmount,
                    globalAwardedJackpot
                } = message

                // UPDATE LOCAL JACKPOT ONGOING AMOUNT
                if (localOngoingJackpotAmount) {
                    setLocalJackpot(localOngoingJackpotAmount)
                }
                // UPDATE GLOBAL JACKPOT ONGOING AMOUNT
                if (globalOngoingJackpotAmount) {
                    setGlobalJackpot(globalOngoingJackpotAmount)
                }

                // CHECK AND SHOW IF GLOBAL JACKPOT HIT
                if (globalAwardedJackpot) {
                    if (globalAwardedJackpot.advertiseTtl > 0) {
                        const {
                            shopName,
                            amount,
                            shopCode,
                            roundNo,
                            jackpotCode
                        } = globalAwardedJackpot
                        setJackpotHit({
                            type: "global",
                            shopName,
                            shopCode,
                            roundNo,
                            jackpotCode,
                            amount
                        })
                        setTimeout(() => {
                            setJackpotHit(null)
                            setShowBoard(true)
                        }, 10000)
                    }
                }

                // if (message.localAwardedJackpot) {
                //     if (message.localAwardedJackpot.advertiseTtl > 0) {
                //         const { shopName, shopId, amount } =
                //             message.localAwardedJackpot
                //         setJackpotHit({
                //             type: "local",
                //             shopId,
                //             shopName,
                //             amount
                //         })
                //         setTimeout(() => {
                //             setJackpotHit(null)
                //         }, 10000)
                //     }
                // }
            })

            connection.on("prepareDraw", (message) => {
                console.log("prepareDraw:", message)

                setPrepareDraw(true)
                setShowCountDown(true)
                setShowBoard(false)
                setShowWins(false)
                // setActualRoundNo(nextRoundNo)
                setRemainingSeconds(message.remainingRoundSeconds)
            })
            connection.on("roundClosed", (message) => {
                console.log("roundClosed:", message)

                const { altitude, newRoundNo, remainingRoundSeconds } = message

                setAltitude(altitude)
                setNextRoundNo(newRoundNo)
                // setActualRoundNo(newRoundNo - 1)
                setRemainingSeconds(remainingRoundSeconds)
                setPrepareDraw(false)
                setShowCountDown(false)
                setBetClosed(false)
            })
            connection.on("historyResults", (message) => {
                console.log("historyResults:", message)

                const { rounds, remainingRoundSeconds } = message

                const delay = parseInt(rounds[0]?.altitude) > 3 ? 5000 : 1000

                setTimeout(() => {
                    setHistory(rounds)
                    setAltitude(null)
                    getTopWins()
                    setShowBoard(true)
                    setShowLoading(false)
                }, delay)

                setRemainingSeconds(remainingRoundSeconds)
            })
        } catch (error) {
            console.error("Error starting SignalR connection:", error)
        }
    }

    return (
        <AppContext.Provider
            value={{
                shopId,
                altitude,
                setAltitude,
                altitudeAnimationInProgress,
                setAltitudeAnimationInProgress,
                remainingSeconds,
                nextRoundNo,
                localJackpot,
                globalJackpot,
                history,
                prepareDraw,
                topWins,
                rocketTable,
                actualRoundNo,
                lastRoundNo,
                lastRoundAltitude,
                showBoard,
                showWins,
                setShowWins,
                jackpotHit,
                animationFinished,
                setAnimationFinished,
                showLoading,
                betClosed,
                showCountDown, setShowCountDown
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

// {

// betsClosed:false
// canCatchUpJackpot:false
// isControllerActive:true
// lastRoundAltitude:1.87
// remainingSeconds:36
// roundHistory:null

// }

// IN 5 sec
// betsClosed:true
// canCatchUpJackpot:true
// remainingSeconds:3
// roundHistory:(20) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]

// betsClosed:false
// canCatchUpJackpot:false
// remainingSeconds:10
// roundHistory:null

// 25 SEC

// betsClosed:false
// canCatchUpJackpot:true
// remainingSeconds:14
// roundHistory:(20) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
