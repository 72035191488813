import * as THREE from "three"
import React from "react"

const GradientBackground = () => {
    const meshRef = React.useRef()

    return (
        <mesh ref={meshRef} position={[0, 0, -550]}>
            <planeGeometry args={[800, 400]} />{" "}
            {/* Large plane to cover the scene */}
            <shaderMaterial
                attach="material"
                uniforms={{
                    // color1: { value: new THREE.Color("#540979") },
                    // color2: { value: new THREE.Color("#2c114b") }, // Gradient to another color
                    color1: { value: new THREE.Color("#1a0725") },
                    color2: { value: new THREE.Color("#110b1b") } // Gradient to another color
                }}
                vertexShader={`
          varying vec2 vUv; 
          void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          }
        `}
                fragmentShader={`
          uniform vec3 color1;
          uniform vec3 color2;
          varying vec2 vUv;
          void main() {
            gl_FragColor = vec4(mix(color1, color2, vUv.y), 1.0);
          }
        `}
            />
        </mesh>
    )
}

export default GradientBackground
